.modal-m.fade .modal-m-dialog {
  transform: translate(0px, -25%);
  transition: transform 0.3s ease-out 0s;
}

.modal-m.fade.in .modal-dialog {
  transform: translate(0px, 0px);
}

.ixTfPP {
  float: left;
  width: 100%;
}

.ciTvcc {
  width: 100%;
}

.kJnpoy {
  border: 1px solid #ccc;
  width: 100%;
}

.cc-input img {
  width: 2.25rem;
  height: 2.25rem;
  margin-right: 0.5rem;
}

.alert-box {
  position: absolute;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.login-card {
  top: 20%;
}

.justify-content-center {
  margin: 0 !important;
}

.flyover {
  left: 150%;
  overflow: hidden;
  position: fixed;
  width: 30%;
  opacity: 0.95;
  z-index: 1050;
  transition: left 0.6s ease-out 0s;
}

.jumbotron {
  padding: 1rem 2rem !important;
}

.flyover-centered {
  top: 10%;
  transform: translate(-50%, -50%);
}

.flyover.in {
  left: 83%;
}

.input_error {
  border-color: #f25961 !important;
  color: #f25961 !important;
}

.input_error::placeholder {
  color: #f25961 !important;
}

.label_error {
  color: #f25961 !important;
}

.payment-desk {
  margin-top: 18px !important;
}

@media (max-width: 768px) {
  .logo-img.coat-of-arms {
    width: 10rem;
  }

  .login-aside + .login-aside.w-50 {
    padding: 0;
  }

  .login-aside + .login-aside.w-50 > .container.container-login {
    padding: 0 !important;
    width: 100%;
  }

  .login-aside + .login-aside.w-50 > .container.container-login > .card {
    border-radius: 0;
    margin-bottom: 0;
  }
}

@media (max-width: 576px) {
  .div-logo {
    padding-top: 0;
  }
}