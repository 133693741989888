body {
  margin-top: 0px;
  background: #efefee;
}

.Toastify__toast--error {
  background: #dc3545!important;
}

.panel-title {
  display: inline;
  font-weight: bold;
}

.checkbox.pull-right {
  margin: 0;
}

.pl-ziro {
  padding-left: 0px;
}

.login-form {
  margin-top: 25px;
}

.static-card img {
  width: 27px !important;
  height: 20px !important;
}

.static-card img:nth-child(2) {
  margin-left: 3px;
}

.kJnpoy {
  margin: 0 !important;
  padding: 0 0.5rem !important;
  align-items: stretch;
}

.form-group .kJnpoy label {
  margin-bottom: 0;
}

.sc-bdVaJa {
  margin: 0 !important;
  padding: 0 !important;
}

.response-data {
  text-align: left;
  padding: 0 1rem;
  margin: 1.25rem 0 2rem;
}

.response-data > .d-flex {
  justify-content: space-between;
}

.response-error {
  text-align: center;
  padding: 0 1rem;
  margin: 1.25rem 0;
}

.response-error span {
  color: #495057;
  font-size: 1rem;
}

.response-data span {
  color: #495057;
}

.response-data .value {
  float: right;
}

.value {
  color: #495057;
}

.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: white;
  border: 1px solid #2d704e;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-ui h3 {
  color: #2d704e;
}

.custom-ui > button {
  width: 160px;
  padding: 10px;
  background: #2d704e !important;
  border: 1px solid #2d704e;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

.conditions-policy {
  text-align: center;
  font-size: 13px;
}

.conditions-policy a,
.conditions-policy span,
.conditions-policy a ~:hover,
.conditions-policy span ~:hover {
  cursor: pointer;
  color: #2d704e;
}

.div-logo {
  padding-top: 20px;
  text-align: center;
  margin-right: 37%;
}

.paybtn {
  margin: 0 auto;
}

.div-logo span {
  margin-left: 14px;
  position: absolute;
  font-size: 20px;
  color: green;
  font-weight: 600;
}

.logo-img {
  width: 60px;
  height: 68px;
  margin-left: 195px;
}

.logo-img.coat-of-arms {
  margin: 0 auto 2rem;
  width: 240px;
  height: auto;
  max-width: 80%;
}


/* Radio button*/

.btn-primary {
  background: #3c845f!important;
  border-color: #3c845f!important;
}

.btn-primary:disabled,
.btn-primary:focus,
.btn-primary:hover {
  background: #2c8e5b!important;
  border-color: #2c8e5b!important;
}

.main-div {
  background: #edeeec;
  height: 100vh;
}

.radiotextsty {
  color: #A5A4BF;
  font-size: 18px;
}

.logo-content {
  text-align: center;
  background: #edeeec;
  padding-top: 15px;
}

.logo {
  max-width: 220px;
}

.customradio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


/* Hide the browser's default radio button */

.customradio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}


/* Create a custom radio button */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #BEBEBE;
}


/* On mouse-over, add a grey background color */

.customradio:hover input~.checkmark {
  background-color: transparent;
}


/* When the radio button is checked, add a blue background */

.customradio input:checked~.checkmark {
  background-color: white;
  border: 1px solid #BEBEBE;
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


/* Show the indicator (dot/circle) when checked */

.customradio input:checked~.checkmark:after {
  display: block;
}


/* Style the indicator (dot/circle) */

.customradio .checkmark:after {
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #3DABFB;
}

.form-check-inline {
  margin-left: 30%;
  margin-right: 0;
}


/*End*/

.success-main img {
  width: 210px;
  margin-top: 63px;
}

.success-main .title {
  font-size: 35px;
  font-weight: 600;
  color: #3FAF91;
}

.s-data {
  font-size: 25px;
  color: #3FAF91;
}

.error-main img {
  width: 210px;
  margin-top: 160px;
}

.error-main .title {
  font-size: 35px;
  font-weight: 600;
  color: #e04a3a;
}

.error-main .error-msg {
  font-size: 25px;
  color: #e04a3a;
}


/*Payment Page Starts*/

.payment-form-bg {
  background: #EEEEED;
}

.payment-form-modal {
  background: #fff;
  border-radius: 10px;
  padding: 0px;
  margin-top: 20px;
  border: 1px solid #ccc;
}

.payment-form-modal .form-group {
  margin-bottom: 0;
}

.btn-rounded {
  border-radius: 60px !important;
}

.payment-form-modal .panel-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
  color: #fff;
  background: #2d704e;
  padding: 10px;
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.payment-form-modal .panel-body {
  padding: 15px;
}

.payment-form-modal .panel-body input {
  margin-bottom: 15px;
}

.payment-form-modal .card-amount {
  font-size: 16px;
  color: #222;
  margin-top: 0px;
  margin-bottom: 15;
}

.payment-form-modal #field-wrapper {
  overflow: visible;
  margin-bottom: 10px;
}

.payment-form-modal #field-wrapper input {
  border: 1px solid #ccc;
  padding: 4px;
  border-radius: 5px;
}

.payment-form-modal .btn-login {
  margin: 10px auto;
  display: block;
  border-radius: 0;
  min-width: 120px;
  background: #febd4b;
  border: 0px;
}

.payment-form-modal .btn-login:hover {
  background-color: #ffae20;
}


/*Payment Page Ends*/

.payment-desk {
  margin-top: 18px !important;
}

.success-main {
  margin-top: 10px;
}

.success-msg .logo-img {
  margin-left: 25%;
  margin-top: 25px;
}

.error-main {
  margin-top: 10px;
}

.error-main img {
  margin-top: 70px;
}

.error-msg .logo-img {
  margin-left: 25%;
  margin-top: 25px;
}

.input-group > .react-datepicker-wrapper {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.wrapper-login .login-aside .left-container {
  position: sticky;
  max-height: 100vh;
  height: 100%;
  top: 0;
}

.wrapper-login .login-aside .left-container > * {
  max-width: 720px;
}